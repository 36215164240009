<a class="contacts__link" href="https://t.me/MarketGuru_Support_bot" target="_blank">
  <img alt="" src="/assets/images/icons/soc-tg-ic.svg" />
  {{ '@MarketGuru_Support_bot' }}
</a>

<a class="contacts__link" href="tel:+79882561000" target="_blank">
  <i class="mg-icon-call"></i>
  +7 (988) 256-1000
</a>

<a class="contacts__link" href="mailto:support@marketguru.io" target="_blank">
  <i class="mg-icon-letter"></i>
  {{ 'support@marketguru.io' }}
</a>
