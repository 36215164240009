import { booleanAttribute, ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-contacts-buttons',
  templateUrl: './contacts.component.html',
  styleUrl: './contacts.component.scss',
  host: {
    '[class.contacts--line]': 'line()'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsButtonsComponent {
  line = input(false, { transform: booleanAttribute });
}
